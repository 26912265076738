import { combineReducers } from 'redux';

import authReducer from './auth';
import loadReducer from './load';
import menuReducer from './menu';
import sportsReducer from './sports';
import p2pReducer from './p2p';
import snackbarReducer from './snackbar';
import load from './load';

const reducer = combineReducers({
    auth: authReducer,
    load: loadReducer,
    menu: menuReducer,
    sports: sportsReducer,
    p2p: p2pReducer,
    snackbar: snackbarReducer
});

export default reducer;
