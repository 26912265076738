import React from "react";
import "./Loader.css";
export default function Loader() {
  return (
    <div className="flex flex-col space-y-4 items-center justify-center w-screen h-screen bg-black">
      <p className="text-yellow-300 glowing-txt">LOADING</p>

      <button className="glowing-btn">
        <span className="glowing-txt">
          BLOCKC<span className="faulty-letter">HAIN </span>BETS
        </span>
      </button>
    </div>
  );
}
